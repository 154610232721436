import moment from 'moment'
import React, { RefObject, useRef, useImperativeHandle, useState } from 'react'
import { createStyles, withStyles, Dialog, DialogContent, DialogTitle, DialogActions, Chip } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { ColumnDescriptor, DataTable, Button, SapChip } from '../../common/customComponents'
import { DealContainer } from './dealEditStore'
import { MovementStatus, MovementListItem, MovementType } from "../../stock/stockModels"
import { MovementStatusIcon } from '../../stock/MovementStatusIcon'
import { MovementTypeIcon, MovementMotIcon } from '../../stock/_movementIcon'
import { muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'

function _assignableMovementsDialog({ classes }) {

    let store = DealContainer.useContainer()

    let [isOpen, setIsOpen] = useState<boolean>(false)

    dialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: () => {
            setIsOpen(true)
        }
    }))

    let close = () => {
        store.setAssignConfirmDialogOpen(false)
        setIsOpen(false)
    }

    let confirm = () => {
        close()
        store.setAssignConfirmDialogOpen(true)
    }

    let getTotalFilteredMovementsVolume = () => {
        let currentMovements = store.assignableMovements
        if (currentMovements == null) return
        return currentMovements.reduce((acc, cur) => acc + (cur.volume ?? 0), 0)
    }

    let getMovementTableColumns = () => {
        let initialMovementColumns: ColumnDescriptor<MovementListItem>[] = [
            { name: t('stock.label.movement.date'), value: x => x.date ? moment(x.date).format('MM/DD') : '' },
            { name: t('stock.label.movement.type'), htmlFor: x => <MovementTypeIcon movementType={x.movementType ?? MovementType.Unknown} /> },
            { name: t('stock.label.movement.referenceExt'), value: x => x.reference, wrapText: true },
            {
                name: t('stock.label.originDestination'), htmlFor: x =>
                    <div className={classes.textSubTextContainer}>
                        <div>{x.text}</div>
                        <div className={classes.subText}>{x.subText}</div>
                    </div>
            },
            { name: t('stock.label.movement.mot'), htmlFor: x => <MovementMotIcon meanOfTransportation={x.meanOfTransportation ?? ''} /> },
            {
                name: t('stock.label.movement.volume', { unit: store.unit }),
                textAlign: 'right',
                total: getTotalFilteredMovementsVolume(),
                value: x => x.volume ? Math.round(x.volume).toString() : null,
            },
            {
                name: t('stock.label.movement.status'), value: x => MovementStatus[x.movementStatus ?? -1],
                htmlFor: x => <MovementStatusIcon movementItem={x} classes={classes} />
            }
        ]

        initialMovementColumns.push(
            {
                name: t('stock.label.movement.sap'), value: x => MovementStatus[x.movementStatus ?? -1],
                htmlFor: x => {
                    return (<>
                        {x.mainSapFlowListItem?.steps.map((stepStatus, index) => {
                            return <SapChip
                                sapChipStyle={sapChipStyle}
                                key={index}
                                isFilled={stepStatus.isCompleted}
                                isFromSap={stepStatus.isFromSap}
                                label={stepStatus.name} />
                        }
                        )}
                    </>)
                }
            })
        return initialMovementColumns
    }

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>{t('deals.label.selectMovementForAssignation')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {!!store.assignableMovements && !!store.assignableMovements.length ?
                    <DataTable tableId={'deal-assignable-movements-table'}
                        items={store.assignableMovements}
                        idSelector={(x: MovementListItem) => x.id}
                        columns={getMovementTableColumns()}
                        onSelectionChange={(x: MovementListItem[]) => store.setSelectedMovements(x)}
                    /> : null}
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
                <Button onClick={confirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={classes.confirmButton}
                    disabled={store.selectedMovements?.length === 0} />
            </DialogActions>
        </Dialog>
    )
}

let dialogRef: RefObject<{ open: () => void }> | null = null
export let assignableMovementsDialog = { open: () => dialogRef?.current?.open() }

const sapChipStyle = {
    borderRadius: '7px',
    marginLeft: '0.2em',
    marginRight: '0.2em'
}

let styles = theme =>
    createStyles({
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        iconStyle: { height: '1.5rem', width: '1.5rem', marginTop: '-0.2rem' },
        invisible: { opacity: 0 },
        red: { color: defaultColors.red.light.color },
        grey: { color: defaultColors.grey.main.color },
        black: { color: 'black' },
        green: { color: defaultColors.green.main.color },
        textSubTextContainer: {
            ...defaultStyles.flexColumn,
            alignItems: 'flex-start'
        },
        subText: {
            fontSize: '0.85em',
            fontStyle: 'Italic',
            color: defaultColors.grey.main.color
        },
        chipRoot: {
            marginLeft: '0.2em',
            marginRight: '0.2em',
            borderRadius: '7px',
        }
    })

export let AssignableMovementsDialog = withStyles(styles, muiOptions)(_assignableMovementsDialog)